<template>
  <a-row :gutter="24">
    <a-col :sm="sm" :md="md" :xl="xl" :style="{ marginBottom: '24px' }">
      <chart-card :loading="false" title="部门业绩" :total="preNumberFormat(analyzeData.total_sales)">
        <a-tooltip title="部门业绩" slot="action">
          <a-icon type="info-circle-o" />
        </a-tooltip>
        <div>
          <trend flag="up" style="margin-right: 16px;">
            <span slot="term">周同比</span>
            12%
          </trend>
          <trend flag="down">
            <span slot="term">日同比</span>
            11%
          </trend>
        </div>
        <template slot="footer">日均销售额<span>￥ 234.56</span> 万</template>
      </chart-card>
    </a-col>
    <a-col :sm="sm" :md="md" :xl="xl" :style="{ marginBottom: '24px' }">
      <chart-card :loading="false" title="成交笔数" :total="priceFormat(analyzeData.count)">
        <a-tooltip title="成交笔数" slot="action">
          <a-icon type="info-circle-o" />
        </a-tooltip>
        <div>
          <mini-bar />
        </div>
        <template slot="footer">转化率 <span>60%</span></template>
      </chart-card>
    </a-col>
  </a-row>
</template>

<script>
import { ChartCard, Bar, Trend, MiniArea, MiniProgress, MiniBar } from '@/components'

export default {
  components: {
    ChartCard,
    Bar,
    Trend,
    MiniArea,
    MiniProgress,
    MiniBar
  },
  props: {
    analyzeData: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      loading: false,
      sm: 24,
      md: 12,
      xl: 12
    }
  },
  mounted() {},
  methods: {}
}
</script>

<style lang="less" scoped>
  /deep/ .chart-card-content,
  /deep/ .chart-card-footer {
    display: none;
  }
</style>
